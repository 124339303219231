<template>
    <div class="main-card">
        <div class="about-card">
           404 page not found
        </div>
    </div>
    </template>
      
      <script>
    
      </script>
      
      <!-- Add "scoped" attribute to limit CSS to this component only -->
      <style scoped>
        .main-card
        {
            width: 60vw;
            height: 100vh;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .about-card
        {
            height: 70vh;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            /* background-color: aliceblue; */
            font-size: 1.5vmax;
            color: white;
        }
      </style>
      